import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box } from "../components/Core";
import Footer from "../components/Footer";
import { HelmetTitle } from "../components/HelmetTitle";
import { isAndroid, isIOS } from "react-device-detect";
import {
  APP_STORE_LINK,
  PLAY_STORE_LINK,
  QrAppLinks,
} from "../components/AppLinks";

const FillHeightBox = styled(Box)`
  @media (min-height: 1000px) {
    height: 100vh;
  }
`;

const SectionStyled = styled(Section)`
  border-bottom: 1px solid #eae9f2;
`;

const CAMPAIGN = "download";

const RedirectingDownloadPage = ({ params }) => {
  if (isIOS) {
    window.location.href = APP_STORE_LINK(CAMPAIGN);
    return null;
  } else if (isAndroid) {
    window.location.href = PLAY_STORE_LINK(CAMPAIGN);
    return null;
  }
  return <DownloadPage />;
};

const DownloadPage = () => (
  <FillHeightBox display="flex" flexDirection="column">
    <HelmetTitle title={`Allo — Download App`} />
    <SectionStyled
      display="flex"
      flexDirection="column"
      flex="1"
      justifyContent="center"
    >
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="7" xl="6">
            <div className="text-center">
              <Title>Download Allo</Title>
            </div>
            <QrAppLinks
              qrUrl={"https://allo.finance/download/"}
              campaign={CAMPAIGN}
            />
          </Col>
        </Row>
      </Container>
    </SectionStyled>
    <Footer isDark={true} />
  </FillHeightBox>
);

export default RedirectingDownloadPage;
